import React, { useState, useEffect, useRef } from 'react'
import { getEditorColor } from '../../util/ColorUtil';
import { isItUndefined, modifyLinks } from "../../util/DataUtil";

//<editor-fold desc="render functions">
const renderItems = (data, textId, setTextId, showAnimation, emphasizedTextColor) => {
    if (typeof window !== 'undefined') {
        const res = window.screen.availWidth;
       
        return data?.map((item, key) => {
            if(!emphasizedTextColor){
                emphasizedTextColor = getEditorColor(isItUndefined(item?.field_why_build_list_item));
            }
            // if(res < 1024){
            //     if(key <= 3){
            //         return (
            //             <div key={'why-build-key-' + key} className={'why-build-item item' + renderSelected(key, textId)} onClick={() => {
            //                 changeAnimation()
            //                 setTextId(key)
            //                 getListItemTopPosition(key)
            //             }}>
            //                 <div className={'list-item list-item-1' + textAnimationEffect(showAnimation) + ' ' + emphasizedTextColor ? emphasizedTextColor : ''} dangerouslySetInnerHTML={{ __html: isItUndefined(item.field_why_build_list_item)}}/>
            //                 <div className={`list-item-2 ${emphasizedTextColor ? emphasizedTextColor : ''}`} dangerouslySetInnerHTML={{ __html: isItUndefined(item.field_why_build_list_item)}}/>
            //             </div>
            //         );
            //     }
            // }
            // else {
            return (
                <div key={'why-build-key-' + key} className={'why-build-item item' + renderSelected(key, textId)} onClick={() => {
                    changeAnimation()
                    setTextId(key)
                    getListItemTopPosition(key)
                }}>
                    <div className={'list-item list-item-1' + textAnimationEffect(showAnimation) + ' ' + emphasizedTextColor} dangerouslySetInnerHTML={{ __html: modifyLinks(isItUndefined(item?.field_why_build_list_item))}}/>
                    <div className={`list-item-2 ${emphasizedTextColor ? emphasizedTextColor : ''}`} dangerouslySetInnerHTML={{ __html: modifyLinks(isItUndefined(item?.field_why_build_list_item))}}/>
                </div>
            );
            // }
        });
    }
}

const renderText = (id, data, emphasizedTextColor) => {
    if (!data){
        return ''
    }
    if(!emphasizedTextColor){
        emphasizedTextColor = getEditorColor(isItUndefined(data[id]?.field_why_build_text))
    }

    return(
        <div>
            <div className={`text ${emphasizedTextColor ? emphasizedTextColor : ''}`} dangerouslySetInnerHTML={{ __html: modifyLinks(isItUndefined(data[id]?.field_why_build_text))}}/>
            {
                isItUndefined(data[id]?.field_why_build_button_text) &&
                <div className={'button'}>
                    <a className={'a-why-build-link'} href={isItUndefined(data[id]?.field_why_build_link)}>{isItUndefined(data[id]?.field_why_build_button_text)}</a>
                </div>
            }
        </div>
    );
}

const renderSelected = (id, textId) => {
    return id === textId ? ' selected' : '';
}
//</editor-fold>

//<editor-fold desc="animation helper functions">
const getListItemTopPosition = (key = 0) => {
    if (typeof window !== 'undefined') {
        const res = window.screen.availWidth;
        const indicator = document.querySelector(".indicator");
        let itemsList = getListItems();
        let size;

        let item1 = itemsList[0];
        let item2 = itemsList[1];
        let item3 = itemsList[2];
        let item4 = itemsList[3];
        let item5 = itemsList[4];

        // if(res < 992){
        //     switch(key) {
        //         case 0:
        //             size = '0';
        //             break;
        //         case 1:
        //             size = item1.width + item2.width / 2 + item1.spaceBetween - 8 + 'px';
        //             break;
        //         case 2:
        //             size = item1.width + item2.width + item3.width / 2 + item1.spaceBetween + item2.spaceBetween - 8 + 'px';
        //             break;
        //         case 3:
        //             size = item1.width + item2.width + item3.width + item4.width / 2 + item1.spaceBetween + item2.spaceBetween + item3.spaceBetween - 8 + 'px';
        //             break;
        //         default:
        //             size = '0';
        //             break;
        //     }
        // }else {
        //     switch(key) {
        //         case 0:
        //             size = '0';
        //             break;
        //         case 1:
        //             size = item1.height / 2 + item1.marginBottom + item2.height / 2 + 'px';
        //             break;
        //         case 2:
        //             size = item1.height / 2 + item2.height + item3.height / 2 + item1.marginBottom * key + 'px';
        //             break;
        //         case 3:
        //             size = item1.height / 2 + item2.height + item3.height + item4.height / 2 + item1.marginBottom * key + 'px';
        //             break;
        //         case 4:
        //             size = item1.height / 2 + item2.height + item3.height + item4.height + item5.height / 2 + item1.marginBottom * key + 'px';
        //             break;
        //         default:
        //             size = '0';
        //             break;
        //     }
        // }

        if(res < 992){
            switch(key) {
                case 0:
                    size = '0';
                    break;
                case 1:
                    size = item1.width + item2.width / 2 + item1.spaceBetween - 8 + 'px';
                    break;
                case 2:
                    size = item1.width + item2.width + item3.width / 2 + item1.spaceBetween + item2.spaceBetween - 8 + 'px';
                    break;
                case 3:
                    size = item1.width + item2.width + item3.width + item4.width / 2 + item1.spaceBetween + item2.spaceBetween + item3.spaceBetween - 8 + 'px';
                    break;
                default:
                    size = '0';
                    break;
            }
        }else {
            size = item1.height * key + item1.marginBottom * key + 'px';
        }

        indicator.style.setProperty("--size", size)
    }
}

const textAnimationEffect = (showAnimation) => {
    return showAnimation ? ' show-animation' : "";
}

const shapesAnimationEffect = (id, showAnimation) => {
    // return showAnimation ? ' show-animation'+ '-' + id : "";
    return showAnimation ? ' show-animation'+ '-' + id % 4 : "";
}

const getAngle = () => {
    let angle = 0;

    let el = document.getElementById("l-big");
    let st = el ? window.getComputedStyle(el, null) : undefined;

    if(!st){
        return;
    }

    let tr = st.getPropertyValue("-webkit-transform") ||
        st.getPropertyValue("-moz-transform") ||
        st.getPropertyValue("-ms-transform") ||
        st.getPropertyValue("-o-transform") ||
        st.getPropertyValue("transform");

    if (tr !== 'none') {
        let values = tr.split('(')[1].split(')')[0].split(',');
        let a = values[0];
        let b = values[1];
        angle = Math.round(Math.atan2(b, a) * (180 / Math.PI));
    }

    return (angle < 0) ? angle + 360 : angle;
}

const changeAnimation = () => {
    const lShape = document.querySelector(".l-big")
    const circleShape = document.querySelector(".circle-shape")

    if(!lShape || !circleShape){
        return;
    }

    const lPosLeft = window.getComputedStyle(lShape).left;
    const circlePosTop = window.getComputedStyle(circleShape).top;
    const circlePosLeft = window.getComputedStyle(circleShape).left;
    let lShapeAngle = getAngle();
    let circleShapeAngle = getAngle();

    lShape.style.setProperty("--cur-pos-left", lPosLeft)
    lShape.style.setProperty("--cur-angle", lShapeAngle)

    circleShape.style.setProperty("--cur-pos-top", circlePosTop)
    circleShape.style.setProperty("--cur-pos-left", circlePosLeft)
    circleShape.style.setProperty("--cur-angle", circleShapeAngle)
}

const getListItems = () => {
    let result = [];

    if (typeof window !== 'undefined') {
        const whyBuildItems = document.getElementsByClassName('items-container');
        const temp = document.querySelector('.items-container .why-build-item');
        let style = temp.currentStyle || window.getComputedStyle(temp);
        let list = whyBuildItems[0].children;

        for(let i = 0; i < list.length; i++){
            let firstItem = list[i];
            let secondItem = list[i + 1];

            let firstItemSpace = firstItem ? firstItem.getBoundingClientRect() : null;
            let secondItemSpace = secondItem ? secondItem.getBoundingClientRect() : null;

            let diff = secondItemSpace?.left - firstItemSpace?.right;

            result.push({
                width: Number(list[i].clientWidth),
                spaceBetween: Number(diff),
                height: Number(list[i].clientHeight),
                marginBottom: Number(style.marginBottom.replace(/\D/g, ""))
            })
        }

        return result;
    }
}
//</editor-fold>

const getBackgroundColor = (color) => {
    switch (color) {
        case '0':
            return 'blue'
        case '1':
            return 'white'
        default:
            return 'blue'
    }
}

const WhyBuild = (data) => {
    const [textId, setTextId] = useState(0);
    const [showAnimation, setShowAnimation] = useState(false);
    const firstUpdate = useRef(true);
    let backgroundColor = isItUndefined(data?.children?.field_choose_color);
    const emphasizedTextColor = getEditorColor(isItUndefined(data?.children?.field_why_build_title));

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        setShowAnimation(true);
    });

    return (
        <div className={'why-build-container ' + getBackgroundColor(backgroundColor)}>
            <div id={'circle-shape'} className={'circle-shape' + shapesAnimationEffect(textId, showAnimation)}>
                <div className={'circle'}>
                    <div className={'cut'} />
                </div>
            </div>
            <div id={'l-big'} className={'l-big' + shapesAnimationEffect(textId, showAnimation)}>
                <div className={'l-small'} />
                <></>
            </div>
            <div className={'inner-container'}>
                <h1 className={`${emphasizedTextColor ? emphasizedTextColor : ''}`} dangerouslySetInnerHTML={{ __html: modifyLinks(isItUndefined(data.children.field_why_build_title))}}/>
                <div className={'container'}>
                    <div className={'left'}>
                        <div className={'indicator'}><div className={'dot'} /></div>
                        <div className={'items-container'}>
                            {renderItems(data?.children?.field_why_build_item?.why_build_paragraph_type, textId, setTextId, showAnimation, emphasizedTextColor)}
                        </div>
                    </div>
                    <div className={'right'}>
                        {renderText(textId, data?.children?.field_why_build_item?.why_build_paragraph_type, emphasizedTextColor)}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhyBuild;
